<template>
  <div class="footer">
    <div class="footers-line fw_w">
      <div class="img_left">
        <img :src="zp" alt="同程直聘" />
        <div style="margin-top:18px">Copyright ©2002-{{(new Date).getFullYear()}} 版权所有</div>
        <div>同程网络科技股份有限公司 | 苏ICP备09033604号-21</div>
      </div>
      <div class="about-us">
        <div>联系我们</div>
        <div
          @click="
            $router.push({
              name: 'qa',
              query: { query: Math.random() },
              params: { activeTab: 3 },
            })
          "
        >
          关于投递
        </div>
        <div @click="getImgCode()">意见反馈</div>
      </div>
      <div class="about-us">
        <div>相关网站</div>
        <a
          href="https://www.ly.com"
          style="color: #ccc; display: block"
          target="_blank"
          >同程旅行</a
        >
      </div>
      <div style="display: inline-block; width: 310px">
        <div style="float: right">
          <div class="mb-20">实时动态与招聘信息，关注我们</div>
          <div class="d-f fw_w">
            <div v-for="(item, index) in limgList" :key="index">
              <a-popover>
                <template slot="content">
                  <div
                    class="popover-img"
                    :style="
                      'background-image:url(' +
                      item.pic +
                      ');width: 260px;height: 260px;background-size: 100%;'
                    "
                  ></div>
                </template>
                <div
                  class="img-list pointer-status"
                  :style="'background-image:url(' + item.logo + ')'"
                ></div>
              </a-popover>
            </div>
          </div>
        </div>
      </div>
    </div>
    <a-modal
      :visible="adviceVisble"
      @ok="handleSubmit"
      width="700px"
      :footer="null"
      @cancel="handleCancel"
    >
      <div class="modal_title">
        <img src="@/assets/images/logomini.png" alt="" />
        感谢您的真诚反馈，我们定认真考虑您的宝贵意见!
      </div>
      <a-form
        :form="form"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 17 }"
        @submit="handleSubmit"
      >
        <a-form-item label="姓名">
          <a-input
            style="width: 100%"
            :maxLength="5"
            allow-clear
            v-decorator="[
              'userName',
              {
                rules: [{ required: false, message: '请输入姓名！' }],
              },
            ]"
          />
        </a-form-item>
        <a-form-item label="性别">
          <a-radio-group
            v-decorator="[
              'sex',
              {
                rules: [{ required: false, message: '请输入性别！' }],
              },
            ]"
          >
            <a-radio value="男"> 男 </a-radio>
            <a-radio value="女"> 女 </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="反馈内容">
          <a-textarea
            style="width: 100%"
            rows="5"
            :maxLength="2000"
            allow-clear
            v-decorator="[
              'content',
              {
                rules: [{ required: true, message: '请输入反馈内容！' }],
              },
            ]"
          />
        </a-form-item>
        <template v-if="showMore">
          <a-form-item label="手机号码">
            <a-input
              style="width: 100%"
              rows="5"
              size="large"
              allow-clear
              v-decorator="[
                'mobile',
                {
                  rules: [
                    { required: true, message: '请输入' },
                    { validator: mobilePass },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item label="图形验证码" class="img_btn">
            <a-input-search
              style="width: 100%"
              placeholder="图形验证码"
              size="large"
              autoComplete="off"
              v-decorator="[
                'imgcode',
                {
                  rules: [{ required: true, message: '图形验证码' }],
                },
              ]"
            >
              <img
                :src="imgSrc"
                @click="setImgCode()"
                slot="enterButton"
                alt=""
              />
            </a-input-search>
          </a-form-item>
          <a-form-item label="短信验证码">
            <a-input-search
              style="width: 100%"
              placeholder="短信验证码"
              size="large"
              autoComplete="off"
              :enter-button="smsTime > 0 ? smsTime : '获取短信验证码'"
              v-decorator="[
                'code',
                {
                  rules: [{ required: true, message: '短信验证码' }],
                },
              ]"
              @search="getMsg"
            />
          </a-form-item>
        </template>
        <a-form-item class="btn" :wrapper-col="{ span: 24 }">
          <div style="text-align: center">
            <a-button
              style="color: #fff; background-color: #5b318f"
              :loading="confirmLoading"
              type="primary"
              size="large"
              html-type="submit"
              >提交反馈</a-button
            >
            <a-button
              size="large"
              class="ml-20"
              type="text"
              @click="handleCancel"
              >取消</a-button
            >
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
// import zp from "@/assets/images/zp.png";
import zp from "@/assets/images/zp3x.png";
import wxl from "@/assets/images/wx.png";
import wx from "@/assets/images/wx.jpg";
import dyl from "@/assets/images/douyin.png";
import dy from "@/assets/images/dy.png";
import tt from "@/assets/images/tt.png";
import bili from "@/assets/images/bili.png";
import xhs from "@/assets/images/xhs.png";
import wb from "@/assets/images/wb.png";
import zh from "@/assets/images/zh.png";
import ins from "@/assets/images/in.png";
import Cookies from "js-cookie";
import axios from "axios";
export default {
  name: "footers",
  data() {
    return {
      limgList: [
        { logo: wxl, pic: wx },
        { logo: dyl, pic: dy },
      ],
      zp: zp,
      adviceVisble: false,
      confirmLoading: false,
      smsTime: -1,
      showMore: false,
      imgSrc: "",
      form: this.$form.createForm(this, { name: "coordinated" }),
    };
  },
  computed: {
    isLogin() {
      return this.$store.state.hasGetInfo;
    },
  },
  methods: {
    handleCancel() {
      this.adviceVisble = false;
      this.form.resetFields();
      this.showMore = false;
      this.smsTime = -1;
    },
    mobilePass(rule, value, callback) {
      if (value)
        if (/^1\d{10}$/.test(value)) {
          callback();
        } else {
          callback("手机号格式错误");
        }
      else {
        callback("请输入手机号");
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (this.isLogin || this.showMore) this.submit(values);
          else this.showMore = true;
        } else {
          this.$message.warning("请输入正确信息");
        }
      });
    },
    async submit(values) {
      this.confirmLoading = true;
      let obj = {};
      if (this.isLogin) {
        obj = {
          ...values,
          mobile: this.$store.state.user.account,
          isLogin: 1,
        };
      } else {
        obj = {
          ...values,
          isLogin: 0,
        };
      }
      let res = await this.apis.submitSuggest({
        ...obj,
      });
      this.confirmLoading = false;
      if (res && !res.code) {
        this.handleCancel();
        this.$message.success(res.message || "提交成功");
      } else {
        this.$message.error(res.message || "提交失败");
      }
    },
    timeDown() {
      let time = 60;
      let that = this;
      let timeGo = setInterval(() => {
        if (time >= 0) {
          time = time - 1;
          that.smsTime = time;
        } else {
          clearInterval(timeGo);
        }
      }, 1000);
    },
    async getMsg() {
      return this.form.validateFields(["mobile", "imgcode"], (err, values) => {
        if (!err) {
          console.log(values, Cookies.get("imgCodeToken"));
          // if(getCookies)
          if (Cookies.get("imgCodeToken") != values.imgcode) {
            this.$message.warning("请输入正确的图片验证码！");
          } else this.getMsgCode(values);
        }
      });
    },
    async setImgCode() {
      axios
        .get("/recruit/ats/portal/getImgCode", { responseType: "blob" })
        .then((response) => {
          this.imgSrc = window.URL.createObjectURL(response.data);
        });
    },
    async getImgCode() {
      this.setImgCode();
      this.adviceVisble = true;
    },
    async getMsgCode(val) {
      if (this.smsTime > 0) return;
      let res = await this.apis.getMsgCode({
        mobile: val.mobile,
        companyId: "0583d",
        type: 3,
      });
      if (res.constructor == String) {
        return this.$message.error(res);
      }
      if (res && !res.code) {
        this.$message.success("消息发送成功");
        this.timeDown();
      } else {
        this.$message.error(res.message || "消息发送失败");
      }
    },
  },
};
</script> 

<style lang="less" scoped>
/deep/ .ant-btn,
.ant-btn-primary,
.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
  border-color: #5b318f;
  color: #5b318f;
}
/deep/.ant-btn-primary,
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background-color: #5b318f;
}
/deep/ .ant-modal-content {
  .anticon {
    color: #fff;
  }
}
/deep/ .img_btn {
  .ant-btn-primary {
    border-color: #d9d9d9;
    background-color: #fff;
  }
}
.modal_title {
  height: 60px;
  width: 100%;
  margin: -24px 0 20px -24px;
  background: #5b318f;
  color: #fff;
  line-height: 60px;
  font-size: 20px;
  padding: 0 24px;
  font-weight: bold;
  width: 100%;
  box-sizing: content-box;
  img {
    height: 48px;
    width: 48px;
    border-radius: 50%;
  }
}
.footer {
  width: 100%;
  font-size: 12px;
  color: #515154;
  background: #282828;
  padding: 64px 0px 70px 0px;
  .about-us {
    width: 320px;
    color: #cccccc;
    border-right: 1px solid #565656;
    height: 90px;
    a,
    div {
      text-align: center;
      padding-top: 12px;
      cursor: pointer;
      &:first-child {
        padding-top: 2px;
        cursor: auto;
        color: #999999;
      }
    }
  }
  .footers-bm {
    padding: 30px 0;
    color: #fff;
    line-height: 17px;
    height: 17px;
    font-size: 12px;
    width: 100%;
    text-align: center;
  }
  .footers-line {
    margin: auto;
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    align-items: flex-start;
    color: #666666;
    > div {
      margin-bottom: 20px;
    }
    .img-list {
      width: 26px;
      height: 26px;
      margin-right: 10px;
      background-size: 100%;
      
    }
    .img_left {
      width: 350px;
      font-size: 12px;
      div {
        line-height: 20px;
      }
      img {
        width: 260px;
        height: 30px;
        display: inline-block;
      }
    }
  }
}
</style>