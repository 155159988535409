<template>
  <div class="list">
    <div class="d-f" v-if="$route.name == 'school'" style="background-color: #fbfbfb;flex-wrap: wrap;">
      <div class="tab_items" :class="state == index ? 'tab_items_s' : ''" v-for="(item, index) in tab" :key="index" @click="setState(item, index)">
        {{ item.unitName }}
      </div>
    </div>
    <div style="background: #fff" v-if="tableList && tableList.length > 0">
      <div class="list-item pointer-status" @click="socialDetail(item)" v-for="(item, index) in tableList" :key="index">
        <div class="item-title mb-10 overflow">
          {{ item.jobName }}
          <span v-show="type === 6 && item.externalAmount>0" class="recommend-award">推荐奖励:{{item.externalAmount}}</span>
        </div>
        <div class="item-span overflow">
          {{ item.workPlace }}&nbsp;|&nbsp;{{ item.recruitmentTypeName }}&nbsp;|&nbsp;发布时间:{{ item.createTime }}
        </div>
      </div>
      <div class="paginations">
        <a-pagination :show-total="(total) => `共 ${total} `" show-quick-jumper :current="page.pageNo" show-size-changer show-less-items :page-size.sync="page.pageSize" :pageSizeOptions="['10', '20', '30', '40']" @showSizeChange="onShowSizeChange" @change="showNoChange" :total="page.total" />
      </div>
    </div>
    <div v-else style="height: 100%">
      <noneMsg></noneMsg>
    </div>
  </div>
</template>

<script>
export default {
  name: "list",

  props: {
    type: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      tab: [],
      state: null,
    };
  },

  computed: {
    tableList() {
      return this.$store.state.tableList;
    },
    page() {
      return this.$store.state.page;
    },
  },

  methods: {
    setState(item, index) {
      this.state = index;
      this.$store.commit("setUnitId", item.id);
    },
    async enterprise(item) {
      let res = await this.apis.enterprise({});
      if (res && !res.code) {
        this.tab = res.data;
      } else {
        this.$message.error(res.message || "获取失败");
      }
    },
    socialDetail(is) {
      let name =
        this.$route.name.indexOf("social") > -1
          ? "socialDetail"
          : this.$route.name.indexOf("school") > -1
          ? "schoolDetail"
          : this.$route.name.indexOf("externalRecommend") > -1
          ? 'externalRecommendDetail'
          : this.$route.name.indexOf("trainee") > -1
          ? "traineeDetail"
          : "homejobDetail";
      this.$router.push({
        name: name,
        query: {
          id: is.id,
          type: this.getType(is.recruitmentTypeName),
        },
      });
    },
    getType(val) {
      switch (val) {
        case "社会招聘":
          return 4;
        case "校园招聘":
          return 3;
        case "实习生招聘":
          return 5;
        case "外部推荐":
          return 6;
      }
    },
    onShowSizeChange(current, pageSize) {
      this.$store.commit("setPage", {
        ...this.page,
        pageSize: pageSize,
        pageNo: current,
      });
    },
    showNoChange(current) {
      this.$store.commit("setPage", {
        ...this.page,
        pageNo: current,
      });
    },
  },

  created() {
    this.enterprise();
  },

  mounted() {},
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  .tab_items {
    margin-right: 40px;
    padding: 10px 0;
    color: #333946;
    cursor: pointer;
    flex-shrink: 0;
    font-size: 18px;
    border-bottom: #fbfbfb 2px solid;
  }
  .tab_items_s {
    color: #5b318f;

    border-bottom: #5b318f 2px solid;
  }

  .paginations {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px 10px;
    background: rgb(251, 251, 251);
    /deep/ .ant-pagination-item-active {
      border-color: #fff;
    }
    /deep/ .anticon {
      color: #81878c;
    }
    /deep/ .ant-pagination-item a {
      font-size: 16px;
      color: #81878c;
    }
    /deep/ .ant-pagination-item-active a {
      color: #5b318f;
    }
  }
  .list-item {
    border-bottom: 1px solid #eee;
    padding: 20px 40px;
    &:last-child {
      border-bottom: 0px solid #eee;
    }
    .item-span {
      font-size: 14px;
      font-family: PingFangSC, PingFangSC-Regular;
      font-weight: 400;
      text-align: left;
      color: #81878c;
      line-height: 20px;
    }
    .item-title {
      font-size: 20px;
      font-family: PingFangSC, PingFangSC-Medium;
      font-weight: 500;
      text-align: left;
      color: #333333;
      line-height: 28px;
    }
  }
}
</style>