import { render, staticRenderFns } from "./UserInfoPC.vue?vue&type=template&id=6645c3b3&"
import script from "./UserInfoPC.vue?vue&type=script&setup=true&lang=ts&"
export * from "./UserInfoPC.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./UserInfoPC.vue?vue&type=style&index=0&id=6645c3b3&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports