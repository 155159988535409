<template>
  <div
    class="headers"
    :style="$route.name == 'home' ? 'background:inherit;' : isNavigatorPage ? 'background:transparent' : ''"
  >
    <img src="@/assets/images/zp3x.png" alt="logo" />
    <ul class="tab">
      <li v-for="(item, index) in tabList" :key="index">
        <a
          :href="item.link"
          class="c-p-w"
          v-if="item.title.indexOf('同程') > -1"
          target="_blank"
          >{{ item.title }}</a
        >
        <a-dropdown
          :class="$route.path == item.link ? 'select' : 'c-p-w'"
          v-else-if="item.link == '/personal'"
          :trigger="['hover']"
          :style="'color:' + (isNavigatorPage ? '#FFF' : color) + '!important;'"
        >
          <router-link :to="item.link">
            {{ item.title }}
          </router-link>
          <a-menu slot="overlay" @click="onClick">
            <a-menu-item key="2"> 个人中心 </a-menu-item>
            <a-menu-item key="3"> 我的简历 </a-menu-item>
            <a-menu-item key="1"> 修改密码 </a-menu-item>
            <a-menu-item key="0"> 退出登录 </a-menu-item>
          </a-menu>
        </a-dropdown>
        <div class="c-p-w" v-else-if="item.link == '/login'">
          <router-link
            :style="
              'color:' +
              (isNavigatorPage ? '#FFF' : color)  +
              ';' +
              (index == tabList.length - 1 ? 'padding-right:0' : '')
            "
            :class="$route.path == item.link ? 'select' : 'c-p-w'"
            :to="`${item.link}?loginSty=1`"
          >
            登录
            <!-- {{ item.title }} -->
          </router-link>
          <span :class="$route.path == item.link ? 'select' : 'c-p-w'"
                :style="'color:' + (isNavigatorPage ? '#FFF' : color) + '!important;'">
            |
          </span>
          <router-link
            :style="
              'color:' +
              (isNavigatorPage ? '#FFF' : color)  +
              ';' +
              (index == tabList.length - 1 ? 'padding-right:0' : '')
            "
            :class="$route.path == item.link ? 'select' : 'c-p-w'"
            :to="`${item.link}?loginSty=2`"
          >
            注册
            <!-- {{ item.title }} -->
          </router-link>
        </div>
        <div
          :style="
            'color:' +
            (isNavigatorPage ? '#FFF' : color)  +
            '!important;' +
            (index == tabList.length - 1 ? 'padding-right:0' : '')
          "
          class="c-p-w"
          v-else
          :class="
            $route.path == item.link || $route.path == item.link2
              ? 'select'
              : ''
          "
          @click="goPart(item.link)"
        >
          {{ item.title }}
        </div>
      </li>
      <a-modal
        title="修改密码"
        :visible="visible"
        :confirm-loading="confirmLoading"
        :footer="null"
        @cancel="handleCancel"
      >
        <a-form :form="form" class="form" @submit="handleSubmit">
          <a-form-item>
            <a-input
              autoComplete="off"
              placeholder="手机号"
              v-decorator="[
                'mobile',
                {
                  rules: [
                    { required: true, message: '手机号' },
                    { validator: mobilePass },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item>
            <a-input
              autoComplete="new-password"
              placeholder="新密码"
              type="password"
              v-decorator="[
                'password',
                {
                  rules: [
                    { required: true, message: '新密码' },
                    { validator: passwordPass },
                  ],
                },
              ]"
            />
          </a-form-item>
          <a-form-item class="btn" :wrapper-col="{ span: 24 }">
            <a-button style="width: 100%" type="primary" html-type="submit"
              >确定</a-button
            >
          </a-form-item>
        </a-form>
      </a-modal>
    </ul>
    <ul class="tab" v-if="$route.path !== '/'">
            <li>
                <div
                    :style="'color:' + (isNavigatorPage ? '#FFF' : color) + '!important;'"
                    class="c-p-w"
                    @click="goPart('schoolTeam')"
                >
                    {{ '校园招聘' }}
                </div>
            </li>
        </ul>
  </div>
</template>

<script>
import { Base64 } from "js-base64";
export default {
  name: "tab",

  props: {
    color: {
      type: String,
      default: "",
    },
      isNavigatorPage:{
          type:Boolean,
          default:false
      },

  },

  data() {
    return {
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this, { name: "login" }),
    };
  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    hasGetInfo() {
      return this.$store.state.hasGetInfo;
    },
    tabList() {
      let list = [
        {
          title: "首页",
          link: "/",
        },
        {
          title: '校园营销大赛',
          link: "/semCompetition",
        },
        {
          title: "社会招聘",
          link: "/socialJob",
        },
        // {
        //   title: "校园招聘",
        //   link: "/school",
        //   link2: "/school",
        // },
        {
          title: "推荐人才",
          link: "/externalRecommendJob"
        },
        // {
        //   title: "实习生招聘",
        //   link: "/trainee",
        // },
        {
          title: "关于我们",
          link: "/about",
        },
        {
          title: "招聘动态",
          link: "/qa",
        },
      ];
      if (this.$route.name == "home") {
        if (this.hasGetInfo) {
          list = [
            {
              title: this.user.applicantName || this.user.account,
              link: "/personal",
            },
          ];
        } else {
          list = [
            {
              title: "登录 | 注册",
              link: "/login",
            },
          ];
        }
      } else {
        if (this.hasGetInfo) {
          list.push({
            title: this.user.applicantName || this.user.account,
            link: "/personal",
          });
        } else {
          list.push({
            title: "登录 | 注册",
            link: "/login",
          });
        }
      }
      return list;
    },
  },

    mounted () {

    },

    methods: {
    mobilePass(rule, value, callback) {
      if (value)
        if (/^1\d{10}$/.test(value)) {
          callback();
        } else {
          callback("手机号格式错误");
        }
      else {
        callback("请输入手机号");
      }
    },
    passwordPass(rule, value, callback) {
      if (value)
        if (value.length >= 6) {
          callback();
          this.password = value;
        } else {
          callback("密码需至少 6 个字符");
        }
      else {
        callback("请输入密码");
      }
    },
    onClick({ key }) {
      switch (key) {
        case "0":
          this.logout();
          break;
        case "1":
          this.opemModal();
          break;
          return;
        case "2":
          if (this.$route.name != "personal")
            this.$router.push({ path: "/personal" });
          break;
        case "3":
          if (this.$route.name != "uploadResume")
            this.$router.push({ path: "/upload-resume" });
          break;
      }
    },
    opemModal() {
      this.visible = true;
    },
    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoading = true;
          this.updateUserInfo(values);
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    async updateUserInfo(val) {
      let res = await this.apis.updateUserInfo({
        mobile: val.mobile,
        password: val.password ? Base64.encode(val.password) : val.password,
        // password: val.password,
      });
      this.confirmLoading = false;
      if (res && !res.code) {
        this.visible = false;
        this.$message.success(res.message || "修改成功");
        this.$store.dispatch("getUserInfo");
      } else {
        this.$message.error(res.message || "修改失败");
      }
    },
    goPart(part) {
      if (part === 'schoolTeam') {
        window.open(`https://mhr.ly.com/recruit/schoolPortal/#/navigation?srt=${Math.random()}`)
        return
      }
      if (part.indexOf("qa") > -1) {
        this.$router.push({
          name: "qa",
          params: { activeTab: 2 },
          query: {
            srt: Math.random(),
          },
        });
      } else
        this.$router.push({
          path: part,
          query: {
            srt: Math.random(),
          },
        });
    },
    async logout() {
      let res = await this.apis.logout();
      if (res && !res.code) {
        this.$message.success(res.message || "退出成功");
        this.$router.push("/");
        this.$store.dispatch("getUserInfo");
      } else {
        this.$message.error(res.message || "退出失败");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.headers {
  height: 64px;
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  line-height: 64px;
  box-sizing: border-box;
  z-index: 11;
  background: #fbfbfb;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
  overflow-x: auto;
  > img {
    height: 27px;
    width: 205px;
  }
  .tab {
    white-space: nowrap;
    margin: 0;
    .select {
      color: #5b318f !important;
      font-size: 18px;
    }
    .c-p-w {
      color: #333946;
    }
    > li {
      display: inline-block;
      // padding: 0 20px;
      z-index: 1;
      color: #333946;
      font-weight: 500;
      margin: 0 20px;
      a {
        text-decoraction: none;
      }
      .router-link-active {
        text-decoration: none;
      }
    }
  }
}
.btn {
  /deep/ .ant-btn-primary {
    background-color: #5b318f;
    border-color: #5b318f;
  }
}
</style>
