var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer"},[_c('div',{staticClass:"footers-line fw_w"},[_c('div',{staticClass:"img_left"},[_c('img',{attrs:{"src":_vm.zp,"alt":"同程直聘"}}),_c('div',{staticStyle:{"margin-top":"18px"}},[_vm._v("Copyright ©2002-"+_vm._s((new Date).getFullYear())+" 版权所有")]),_c('div',[_vm._v("同程网络科技股份有限公司 | 苏ICP备09033604号-21")])]),_c('div',{staticClass:"about-us"},[_c('div',[_vm._v("联系我们")]),_c('div',{on:{"click":function($event){_vm.$router.push({
            name: 'qa',
            query: { query: Math.random() },
            params: { activeTab: 3 },
          })}}},[_vm._v(" 关于投递 ")]),_c('div',{on:{"click":function($event){return _vm.getImgCode()}}},[_vm._v("意见反馈")])]),_vm._m(0),_c('div',{staticStyle:{"display":"inline-block","width":"310px"}},[_c('div',{staticStyle:{"float":"right"}},[_c('div',{staticClass:"mb-20"},[_vm._v("实时动态与招聘信息，关注我们")]),_c('div',{staticClass:"d-f fw_w"},_vm._l((_vm.limgList),function(item,index){return _c('div',{key:index},[_c('a-popover',[_c('template',{slot:"content"},[_c('div',{staticClass:"popover-img",style:('background-image:url(' +
                    item.pic +
                    ');width: 260px;height: 260px;background-size: 100%;')})]),_c('div',{staticClass:"img-list pointer-status",style:('background-image:url(' + item.logo + ')')})],2)],1)}),0)])])]),_c('a-modal',{attrs:{"visible":_vm.adviceVisble,"width":"700px","footer":null},on:{"ok":_vm.handleSubmit,"cancel":_vm.handleCancel}},[_c('div',{staticClass:"modal_title"},[_c('img',{attrs:{"src":require("@/assets/images/logomini.png"),"alt":""}}),_vm._v(" 感谢您的真诚反馈，我们定认真考虑您的宝贵意见! ")]),_c('a-form',{attrs:{"form":_vm.form,"label-col":{ span: 5 },"wrapper-col":{ span: 17 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'userName',
            {
              rules: [{ required: false, message: '请输入姓名！' }],
            },
          ]),expression:"[\n            'userName',\n            {\n              rules: [{ required: false, message: '请输入姓名！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"maxLength":5,"allow-clear":""}})],1),_c('a-form-item',{attrs:{"label":"性别"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'sex',
            {
              rules: [{ required: false, message: '请输入性别！' }],
            },
          ]),expression:"[\n            'sex',\n            {\n              rules: [{ required: false, message: '请输入性别！' }],\n            },\n          ]"}]},[_c('a-radio',{attrs:{"value":"男"}},[_vm._v(" 男 ")]),_c('a-radio',{attrs:{"value":"女"}},[_vm._v(" 女 ")])],1)],1),_c('a-form-item',{attrs:{"label":"反馈内容"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'content',
            {
              rules: [{ required: true, message: '请输入反馈内容！' }],
            },
          ]),expression:"[\n            'content',\n            {\n              rules: [{ required: true, message: '请输入反馈内容！' }],\n            },\n          ]"}],staticStyle:{"width":"100%"},attrs:{"rows":"5","maxLength":2000,"allow-clear":""}})],1),(_vm.showMore)?[_c('a-form-item',{attrs:{"label":"手机号码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              {
                rules: [
                  { required: true, message: '请输入' },
                  { validator: _vm.mobilePass },
                ],
              },
            ]),expression:"[\n              'mobile',\n              {\n                rules: [\n                  { required: true, message: '请输入' },\n                  { validator: mobilePass },\n                ],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"rows":"5","size":"large","allow-clear":""}})],1),_c('a-form-item',{staticClass:"img_btn",attrs:{"label":"图形验证码"}},[_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'imgcode',
              {
                rules: [{ required: true, message: '图形验证码' }],
              },
            ]),expression:"[\n              'imgcode',\n              {\n                rules: [{ required: true, message: '图形验证码' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"图形验证码","size":"large","autoComplete":"off"}},[_c('img',{attrs:{"slot":"enterButton","src":_vm.imgSrc,"alt":""},on:{"click":function($event){return _vm.setImgCode()}},slot:"enterButton"})])],1),_c('a-form-item',{attrs:{"label":"短信验证码"}},[_c('a-input-search',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [{ required: true, message: '短信验证码' }],
              },
            ]),expression:"[\n              'code',\n              {\n                rules: [{ required: true, message: '短信验证码' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"placeholder":"短信验证码","size":"large","autoComplete":"off","enter-button":_vm.smsTime > 0 ? _vm.smsTime : '获取短信验证码'},on:{"search":_vm.getMsg}})],1)]:_vm._e(),_c('a-form-item',{staticClass:"btn",attrs:{"wrapper-col":{ span: 24 }}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('a-button',{staticStyle:{"color":"#fff","background-color":"#5b318f"},attrs:{"loading":_vm.confirmLoading,"type":"primary","size":"large","html-type":"submit"}},[_vm._v("提交反馈")]),_c('a-button',{staticClass:"ml-20",attrs:{"size":"large","type":"text"},on:{"click":_vm.handleCancel}},[_vm._v("取消")])],1)])],2)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"about-us"},[_c('div',[_vm._v("相关网站")]),_c('a',{staticStyle:{"color":"#ccc","display":"block"},attrs:{"href":"https://www.ly.com","target":"_blank"}},[_vm._v("同程旅行")])])
}]

export { render, staticRenderFns }