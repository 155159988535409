<template>
  <div class="noneMsg">
    <div class="none" :style="`background-image:url(${none})`"></div>
    <div class="data">暂无数据</div>
  </div>
</template>

<script>
import none from "@/assets/images/none.png";
export default {
  name: "noneMsg",
  data() {
    return {
      none: none,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.noneMsg {
  width: 100%;
  height: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 30px 0;
  justify-content: center;
  flex-direction: column;
  .none {
    width: 371px;
    height: 186px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
  }
  .data {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    color: #333333;
    margin-top: 40px;
  }
}
</style>
