import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import api from '@/api/index'

export default new Vuex.Store({
  state: {
    showFooter:true,
    user:{
    
    },
    page:{
      pageNo:1,
      pageSize:10
    },
    unitId:null,
    hasGetInfo:false,
    tableList:[]
  },
  mutations: {
    setFooter(state, data, rootState) {
      state.showFooter = data
    },
    setUser(state, data, rootState) {
      state.user =data. data
      state.hasGetInfo = data.hasGetInfo
    },
    setTable(state, data, rootState) {
      state.tableList = data
    },
    setPage(state, data, rootState) {
      state.page = data
    },
    setUnitId(state, data, rootState) {
      state.unitId = data
    },
  },
  actions: {
    // 获取用户相关信息
    getUserInfo ({ state, commit }) {
      return new Promise((resolve, reject) => {
        try {
          api.getUserInfo().then(res => {
            let obj={
              data:res.data,
              hasGetInfo:res.data?true:false,
            }
            commit('setUser',obj)
            resolve(obj)
          }).catch(err => {
            let obj={
              data:{},
              hasGetInfo:false,
            }
            commit('setUser',obj)

            reject(err)
          })
        } catch (error) {
          reject(error)
        }
      })
    }
  },
  modules: {
  }
})
